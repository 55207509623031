'use client'

import { useEffect } from 'react'
import * as Sentry from '@sentry/nextjs'

import { NotFoundPage } from '@/components/not-found-page'

export default function Error({ error }: { error: Error & { digest?: string } }) {
  useEffect(() => {
    Sentry.captureException(error, {
      tags: {
        some: 'error tsx',
      },
      level: 'warning',
    })
  }, [error])

  return <NotFoundPage type="500" />
}
